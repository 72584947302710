import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {StoreOptions} from "../../models/store";
import {StoreService} from "../store.service";
import {SettingsStoreService} from "./settings-store.service";
import {Observable} from "rxjs";

export interface Widget {
    token?: string;
    created_at?: string;
    settings?: StoreOptions;
    active?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class WidgetService {
    private _apiKey : string;

    static readonly DEFAULT_HTTP_OPTIONS = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    constructor(
        private http: HttpClient,
        private settingsStoreService: SettingsStoreService
    ) {
        settingsStoreService.apiToken$.subscribe(apiKey => this._apiKey = apiKey);
    }

    createWidget(widget : Widget) : Observable<Widget> {
        return this.http.post<Widget>(`/api/widget/${this._apiKey}`, JSON.stringify(widget),  WidgetService.DEFAULT_HTTP_OPTIONS);
    }

    getWidget(token : string) : Observable<Widget> {
        return this.http.get<Widget>(`/api/widget/${this._apiKey}/${token}`,  WidgetService.DEFAULT_HTTP_OPTIONS);
    }

    putWidget(token: string, widget: Widget) : Observable<Widget> {
        return this.http.put<Widget>(`/api/widget/${this._apiKey}/${token}`, JSON.stringify(widget), WidgetService.DEFAULT_HTTP_OPTIONS);
    }

    deleteWidget(token : string) : Observable<string> {
        return this.http.delete<string>(`/api/widget/${this._apiKey}/${token}`,  WidgetService.DEFAULT_HTTP_OPTIONS);
    }

}
