import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {StoreService} from "./store.service";
import {Ticket} from "./ticket";
import {Observable} from "rxjs";
import {skipWhile, switchMap, takeWhile, tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class TicketService {

    constructor(
        private http: HttpClient,
        private storeService: StoreService
    ) {
    }

    fetchTicket(ticketId: string, phoneNumber?: string) : Observable<Ticket> {
        return this.storeService.storeToken$
            .pipe(
                skipWhile(val => val == null),
                switchMap(_ => this.http.get<Ticket>(`/ticket/${this.storeService.getStoreToken()}/${ticketId}${ phoneNumber != null ? `?phoneNumber=${phoneNumber}` : ''}`))
            );
    }
}
