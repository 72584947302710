import {Component, OnInit} from '@angular/core';
import {SettingsStoreService} from "../settings-store.service";
import {MessengerService} from "../messenger.service";

@Component({
    selector: 'settings-index',
    templateUrl: './settings-index.component.html',
    styleUrls: ['./settings-index.component.scss']
})
export class SettingsIndexComponent implements OnInit {

    constructor(
        public settingsStoreService: SettingsStoreService,
    ) {
    }

    ngOnInit(): void {
    }

    submit() {
        this.settingsStoreService.storeSettings$.next({
            ...this.settingsStoreService.storeSettings$.getValue(),
            widgets: [
                ...this.settingsStoreService.storeSettings$.getValue().widgets,
                {
                    token: null,
                    created_at: null,
                    active: true,
                    settings: {
                        logo: {
                            enabled: this.settingsStoreService.storeSettings$.getValue().store.store_logo != undefined,
                            path: this.settingsStoreService.storeSettings$.getValue().store.store_logo
                        },
                        show: {},
                        requirements: {},
                    }
                }
            ]
        })
    }


}
