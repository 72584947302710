import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ContainerComponent} from "./container/container.component";
import {SettingsIndexComponent} from "./index/settings-index.component";

const routes: Routes = [
    { path: '', component: ContainerComponent, children: [
        { path: '', pathMatch: 'full', component: SettingsIndexComponent }
    ] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
