import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ContainerComponent} from "./container/container.component";
import {ShowCaseInfoComponent} from "./show-case-info/show-case-info.component";
import {SearchBoxComponent} from "./search-box/search-box.component";

const routes: Routes = [
    { path: 'settings', pathMatch: 'prefix', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)},
    { path: '', component: ContainerComponent, children: [
        { path: 'lookup/:ticket', component: ShowCaseInfoComponent },
        { path: '', pathMatch: 'full', component: SearchBoxComponent },
    ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
