import { Component, OnInit } from '@angular/core';

import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import { Ticket } from 'src/app/ticket';
import { HttpClient } from '@angular/common/http';

import {switchMap, map, filter, takeUntil, tap, skip, skipWhile} from 'rxjs/operators';

import * as moment from 'moment';
import {TicketService} from "../ticket.service";
import {BehaviorSubject, combineLatest, forkJoin, Subject} from "rxjs";
import {ErrorService} from "../error.service";
import {StoreService} from "../store.service";

@Component({
    selector: 'app-show-case-info',
    templateUrl: './show-case-info.component.html',
    styleUrls: ['../app.component.scss', './show-case-info.component.scss']
})
export class ShowCaseInfoComponent implements OnInit {
    publicToken: string;
    loading = true;

    showErrAlert = false;
    momentFormat: string = 'dddd [den] LLL';
    showables: { timestamps: boolean } = {
        timestamps: true
    };
    showProducts = false;
    showProductsInfo = {
        singular: false
    };

    // Meta
    ticketInfo$ : BehaviorSubject<Ticket> = new BehaviorSubject<Ticket>(null);

    friendlyStatusNames = {
        done: 'Sagen er afsluttet',
        ready: 'Sagen er færdig og klar til afhentning',
        awaiting: 'Afventer',
        new: 'Igangværende'
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private ticketService : TicketService,
        private errorService : ErrorService,
        private storeService : StoreService
    ) {}

    timestampToDateObject(timestamp : string) : moment.Moment {
        return moment(timestamp);
    }

    ngOnInit(): void {
        const done$ : Subject<any> = new Subject<any>();

        moment.locale('da');
        this.storeService.storeOptions$.subscribe(x => {
            this.showables.timestamps = x.show?.timestamps;
            this.momentFormat = this.showables.timestamps ? 'dddd [den] LLL' : 'dddd [den] LL';
        })

        combineLatest([this.route.paramMap, this.route.queryParamMap])
            .pipe(
                takeUntil(done$),
                switchMap(([routeParams, queryParams] : [ParamMap, ParamMap]) => this.ticketService.fetchTicket(routeParams.get("ticket"), queryParams.get("phoneNumber") ?? null)),
                skipWhile(x => x.meta == undefined)
            )
            .subscribe({
                next: (data: Ticket) => {
                    this.ticketInfo$.next(data);
                    this.loading = false;
                },
                error: (err : any) => {
                    this.errorService.sendError(err.message);
                    this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
                }
            });

    }

}
