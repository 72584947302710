import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import {SettingsModule} from "./settings/settings.module";
import {ContainerComponent} from "./container/container.component";
import {NotApplicableComponent} from "./not-applicable/not-applicable.component";
import {SearchBoxComponent} from "./search-box/search-box.component";
import {ShowCaseInfoComponent} from "./show-case-info/show-case-info.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";


@NgModule({
    declarations: [
        AppComponent,
        ContainerComponent,
        NotApplicableComponent,
        SearchBoxComponent,
        ShowCaseInfoComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        SettingsModule,

        FormsModule,
        ReactiveFormsModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
