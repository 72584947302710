import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Store, StoreOptions, StoreResponse} from '../models/store';
import {map, tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class StoreService {
    storeExists$    : BehaviorSubject<"yes"|"no"|"awaiting"> = new BehaviorSubject<"yes" | "no" | "awaiting">("awaiting");
    storeToken$     : BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    storeOptions$   : BehaviorSubject<StoreOptions> = new BehaviorSubject<StoreOptions>({});

    constructor(
        private http : HttpClient
    ) { }

    validateStoreToken(storeToken : string) : Observable<StoreResponse> {
        return this.http.get<StoreResponse>(`/validate/token/${storeToken}`)
            .pipe(
                tap(res => {
                    if (res.status != "exists") {
                        throw new Error("Store doesn't exists.")
                    }
                })
            );
    }

    setStoreToken(storeToken : string) : Observable<StoreResponse> {
        return this.validateStoreToken(storeToken).pipe(
            tap(data => {
                    this.storeToken$.next(storeToken);
                    this.storeOptions$.next(this.parseStoreOptions(data.options));
                    this.storeExists$.next("yes");
                },
                () => {
                    this.storeExists$.next("no");
                }
            )
        )
    }

    parseStoreOptions(storeOptions : string) : StoreOptions {
        return JSON.parse(storeOptions);
    }

    getLogo() : string | false {
        return this.storeOptions$.getValue().logo?.enabled && this.storeOptions$.getValue().logo?.path;
    }

    getStoreToken() : string | false {
        return this.storeToken$.getValue() ;
    }

}
