<h1>Online Sagssystem &ndash; Indstillinger</h1>

<ng-container *ngIf=" (messengerService.message$ | async) as message">
    <ng-container *ngIf="message.body != null">
        <div class="alert alert-{{message.type}}">
            {{message.body}}
            <button (click)="messengerService.message$.next({type:'success', body: null})" *ngIf="!message.cannotDismiss">&times;</button>
        </div>
    </ng-container>
</ng-container>

<router-outlet></router-outlet>
