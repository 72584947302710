import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'settings-widget-snippet',
  templateUrl: './widget-snippet.component.html',
  styleUrls: ['./widget-snippet.component.scss']
})
export class WidgetSnippetComponent implements OnInit {

  constructor() { }

    @Input() widget;

  ngOnInit(): void {
  }

}
