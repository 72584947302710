<ng-container *ngIf="storeService.storeExists$ | async as storeExists">
    <div [class.loading]="storeExists === 'awaiting'">
        <div class="logo" *ngIf="storeService.getLogo() as logo">
            <img [src]="logo" alt="Logo" height="45px">
        </div>
        <div class="logo-placeholder" *ngIf="storeExists === 'awaiting'"></div>

        <h1>Følg min sag</h1>

        <router-outlet *ngIf="storeExists !== 'no'"></router-outlet>
        <app-not-applicable *ngIf="storeExists === 'no'"></app-not-applicable>
    </div>
</ng-container>
