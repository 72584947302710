import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

    error$ : BehaviorSubject<string> = new BehaviorSubject<string>(null);

    sendError(message : string) : void {
        this.error$.next(message);
    }

  constructor() { }
}
