import { Injectable } from '@angular/core';
import {Subject, Subscription} from "rxjs";

type MessageType = 'success' | 'warning' | 'danger';
interface Message {
    body: string;
    type: MessageType;
    cannotDismiss?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class MessengerService {
    public message$ : Subject<Message> = new Subject<Message>();

    constructor() { }

}
