import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {SettingsStoreService} from "../settings-store.service";
import {map, skipWhile, switchMap, takeUntil, tap, timeout} from "rxjs/operators";
import {MessengerService} from "../messenger.service";

@Component({
    selector: 'settings-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private settingsStoreService: SettingsStoreService,
        public messengerService: MessengerService
    ) {
    }

    ngOnInit(): void {
        const done$ : Subject<null> = new Subject();
        this.route.queryParamMap
            .pipe(
                skipWhile(x => x.get("sp_api_token") == null),
                map(data => data.get("sp_api_token")),
                switchMap(token => this.settingsStoreService.setStore(token))
            ).subscribe({ next: () => done$.next(), error: () => {
                this.messengerService.message$.next({
                    type: "danger",
                    body: "Der skete en fejl ved indlæsning af din Servicepos-butik.",
                    cannotDismiss: true
                })
            } });
    }

}
