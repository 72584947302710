import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ErrorService} from "../error.service";
import {StoreService} from "../store.service";
import {StoreOptions} from "../../models/store";
import {skipWhile, tap} from "rxjs/operators";

@Component({
    selector: 'app-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['../app.component.scss', './search-box.component.scss']
})

export class SearchBoxComponent implements OnInit {
    lookupForm: FormGroup;

    phoneNumber: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        public errorService: ErrorService,
        public storeService : StoreService
    ) {
    }

    ngOnInit(): void {
        this.storeService.storeOptions$
            .pipe(skipWhile(x => x == {}))
            .subscribe({
                next: (storeOptions) => {
                    if(storeOptions.requirements?.phone) {
                        this.lookupForm = this.fb.group({
                            ticketId: ['',
                                [
                                    Validators.required,
                                    Validators.pattern('\\d{1,64}')
                                ]
                            ],
                            phoneNumber: ['',
                                [
                                    Validators.required,
                                    Validators.pattern('\\d{1,64}')
                                ]
                            ]
                        });
                    } else {
                        this.lookupForm = this.fb.group({
                            ticketId: ['',
                                [
                                    Validators.required,
                                    Validators.pattern('\\d{1,64}')
                                ]
                            ]
                        });
                    }
                }
            });






        /*this.generalService.getRequirements().subscribe(x => {
            this.phoneRequired = x.phone ?? false;
            if (x.phone) {

            }
        });*/

    }

    lookup(ticket: string, options: {
        phone: string
    }): void {
        this.router.navigate([`/lookup/${ticket}`], { queryParamsHandling: 'merge', queryParams: {
            phoneNumber: this.phoneNumber
        } });
    }

}
