import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, of} from "rxjs";
import {StoreOptions} from "../../models/store";
import {catchError, tap} from "rxjs/operators";
import {Widget} from "./widget.service";

interface SettingsStoreResponse {
    store_exists: boolean;
    store?: {
        store_id?: number;
        store_name?: string;
        store_logo?: string;
    };
    widgets?: Array<Widget>
}

@Injectable({
    providedIn: 'root'
})
export class SettingsStoreService {

    constructor(
        private http: HttpClient
    ) {
    }

    public storeSettings$ : BehaviorSubject<SettingsStoreResponse> = new BehaviorSubject<SettingsStoreResponse>(null);
    public apiToken$ : BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public refreshStore() {
        if(this.apiToken$.getValue() == null) {
            return;
        }

        this.setStore(this.apiToken$.getValue()).subscribe();
    }

    getStoreSettings(apiToken : string) : Observable<SettingsStoreResponse> {
        return this.http.get<SettingsStoreResponse>(`/api/store/${apiToken}`);
    }

    setStore(apiToken : string) {
        return this.getStoreSettings(apiToken).pipe(
            tap(response => {
                this.storeSettings$.next(response);
                this.apiToken$.next(apiToken);
            }),
            tap({error: () => {
                this.storeSettings$.next({store_exists : false});
            }})
        )
    }


}
