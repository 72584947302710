<ng-container *ngIf="(storeService.storeOptions$ | async) as storeOptions">
    <p class="lead" *ngIf="!storeOptions.requirements?.phone; else secondLead">Indtast sagsnummeret for at spore sagen</p>
    <ng-template #secondLead>
        <p class="lead">Indtast sagsnummer og dit telefonnummer for at spore sagen</p>
    </ng-template>


    <div class="alert alert-danger" *ngIf="errorService.error$ | async as error">
        <p>
            <b>Fejl!</b>
            <span>
                Vi kunne ikke finde en sag på pågældende sagsnummer. Prøv igen.
            </span>
        </p>
    </div>

    <ng-container *ngIf="lookupForm">
        <form (ngSubmit)="lookup( ticketId.value, { phone: phoneNumber } );" [formGroup]="lookupForm" #form="ngForm">
            <div class="wrapbtn">
                <div style="width: 100%;">
                    <input #ticketId [style.height]="storeOptions.requirements?.phone ? '50%' : '100%'"
                           id="ticketId"
                           name="ticketId"
                           placeholder="Sagsnummer"
                           class="form-control"
                           style="width:100%;"

                           type="number"
                           onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                           required="required"
                           formControlName="ticketId"
                    >

                    <input #phoneNumberField style="height: 50%;"
                           *ngIf="storeOptions.requirements?.phone"
                           id="phoneNumber"
                           name="phoneNumber"
                           placeholder="Telefonnummer"
                           class="form-control"
                           style="width:100%;"

                           type="phone"
                           onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                           required="required"
                           formControlName="phoneNumber"

                           [(ngModel)]="phoneNumber"
                    >

                </div>

                <button type="submit" [disabled]="!lookupForm.valid">
                    Se status
                </button>
            </div>

        </form>
    </ng-container>
</ng-container>


