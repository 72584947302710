<ng-container *ngIf="(ticketInfo$|async).meta != undefined && ticketInfo$ | async as ticket">
    <p class="lead">#{{ ticket.meta.id }}</p>

    <ol class="status-steps">
        <li [class]="(ticket.meta.status == 'new') ? 'done' : ''">
            Varen er indleveret
        </li>
        <li [class]="(ticket.meta.status == 'awaiting') ? 'done' : ''">
            Sagen afventer
        </li>
        <li [class]="(ticket.meta.status == 'ready') ? 'done' : ''">
            Varen er klar til afhentning
        </li>
        <li [class]="(ticket.meta.status == 'done') ? 'done' : ''">
            Sagen er afsluttet
        </li>
    </ol>

    <div class="data-grid">
        <div class="status">
            <h3>Status</h3>
            <p [class.loader]="loading">{{ friendlyStatusNames[ticket.meta.status] }}</p>
        </div>
        <div class="description">
            <h3>Opgavebeskrivelse</h3>
            <p [class.loader]="loading">
                {{ ticket.meta.subject }}
            </p>
        </div>
        <div class="created">
            <h3>Indleveret</h3>
            <p [class.loader]="loading"><span *ngIf="ticket.meta.created != undefined">{{ timestampToDateObject(ticket.meta.created).format(momentFormat) }}</span></p>
        </div>
        <div class="pickup">
            <h3>Planlagt afhentningstidspunkt</h3>
            <p [class.loader]="loading"><span *ngIf="ticket.meta.pickup != undefined">{{ timestampToDateObject(ticket.meta.pickup).format(momentFormat) }}</span></p>
        </div>
        <div class="device" *ngIf="showProducts">
            <h3 *ngIf="showProductsInfo.singular">Om produktet</h3>
            <h3 *ngIf="!showProductsInfo.singular">Om produkterne</h3>
            <p *ngFor="let product of ticket.customerarticles" [style]="{ 'margin-bottom': (!showProductsInfo.singular) ? '0px' : 'initial' }">{{ product.title }}</p>
        </div>
    </div>

</ng-container>
