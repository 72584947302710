import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-not-applicable',
    templateUrl: './not-applicable.component.html',
    styleUrls: ['./not-applicable.component.scss']
})
export class NotApplicableComponent implements OnInit {

    year: number;

    constructor() {
    }

    ngOnInit(): void {
        this.year = (new Date()).getUTCFullYear();
    }

}
