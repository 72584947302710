import { Component, OnInit } from '@angular/core';
import {StoreService} from "../store.service";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {filter, map, switchMap, takeUntil, tap, timeout} from "rxjs/operators";
import {Subject} from "rxjs";

const STORE_TOKEN_PARAM = 'cid';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

  constructor(
      public route: ActivatedRoute,
      public storeService : StoreService
  ) { }

  ngOnInit(): void {
      if (this.storeService.getStoreToken() != false) {

          const done$ : Subject<any> = new Subject();
          this.route.queryParamMap.pipe(
              takeUntil(done$), timeout(1000),
              map((params: ParamMap) => params.get(STORE_TOKEN_PARAM)),
              filter(param => param != null),
              switchMap((token: string) => this.storeService.setStoreToken(token))
          ).subscribe(_ => {
              done$.next();
          }, _ => {
              this.storeService.storeExists$.next("no");
          });

      }
  }

}
