import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Widget, WidgetService} from '../widget.service';
import {SettingsStoreService} from '../settings-store.service';
import {MessengerService} from '../messenger.service';

@Component({
  selector: 'settings-edit-widget',
  templateUrl: './edit-widget.component.html',
  styleUrls: ['./edit-widget.component.scss']
})
export class EditWidgetComponent implements OnInit {

  constructor(
      private widgetService: WidgetService,
      private settingsStoreService: SettingsStoreService,
      private messengerService: MessengerService
  ) { }

    widgetCopy: Widget;
    @Input() widget: Widget;

    showSnippetModal: boolean = false;

  ngOnInit(): void {
      this.widgetCopy = JSON.parse(JSON.stringify(this.widget));
      moment().locale('da');

      if (moment(this.widget.created_at).isAfter(moment().subtract(1, 'minute'))) {
          this.showSnippetModal = true;
      }

  }

    timestampToDateObject(timestamp: string): moment.Moment {
        return moment(timestamp);
    }

    deleteWidget(widget): void {
      const c = confirm('Er du sikker på, at du vil slette denne widget?');
      if (!c.valueOf()) {
          return;
      }

      const {token} = widget;
      this.widgetService.deleteWidget(token).subscribe(

          () => {
          },
          (err) => {
              this.messengerService.message$.next({
                  type: 'danger',
                  body: 'Der skete en fejl, så widgeten ikke blev slettet! ' + err.message
              });
              console.error(err);
          },
          () => {
              this.settingsStoreService.refreshStore();
              this.messengerService.message$.next({
                  type: 'success',
                  body: 'Widgeten blev slettet!'
              });
          });


    }

    toggleSnippetModal(): void {
      this.showSnippetModal = !this.showSnippetModal;
    }

    editable(): boolean {
      return (JSON.stringify(this.widget) !== JSON.stringify(this.widgetCopy)) || this.widget.token == null;
    }

    submit(widget: Widget): void {
        if (widget.token == null) {
          // CREATE

          this.widgetService.createWidget(widget).subscribe({
              next: () => {
                  this.messengerService.message$.next({
                      type: 'success',
                      body: 'Din widget er nu oprettet'
                  });
              },
              error: (err) => {
                  this.messengerService.message$.next({
                      type: 'danger',
                      body: 'Der skete en fejl under oprettelse af din widget'
                  });
                  console.error(err);
              },
              complete: () => {
                  this.settingsStoreService.refreshStore();
              }
          });

      } else {
          // UPDATE

          this.widgetService.putWidget(widget.token, widget).subscribe({
              next: () => {
                  this.messengerService.message$.next({
                      type: 'success',
                      body: 'Din widget er nu rettet'
                  });
              },
              error: (err) => {
                  this.messengerService.message$.next({
                      type: 'danger',
                      body: 'Der skete en fejl under rettelsen af din widget'
                  });
                  console.error(err);
              },
              complete: () => {
                  this.settingsStoreService.refreshStore();
              }
          });

      }


        return null;
    }

}
