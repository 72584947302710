<div class="edit-widget">
    <div class="flex f-distance">
        <h3>{{ widget.token || "Ny widget" }} <span *ngIf="!widget.active" class="badge-red">Deaktiveret</span></h3>
        <div class="actions">
            <a title="Gå til sagsstatus-siden" [routerLink]="['/']" [queryParams]="{cid:widget.token}" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M17 7h-4v2h4c1.65 0 3 1.35 3 3s-1.35 3-3 3h-4v2h4c2.76 0 5-2.24 5-5s-2.24-5-5-5zm-6 8H7c-1.65 0-3-1.35-3-3s1.35-3 3-3h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-2zm-3-4h8v2H8z"/></svg>
            </a>&nbsp;
            <button title="Se snippet til din hjemmeside" (click)="toggleSnippetModal()" [class.active]="showSnippetModal">
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><g><rect fill="none" height="24" width="24"/><circle cx="12" cy="3.5" fill="none" r=".75"/><circle cx="12" cy="3.5" fill="none" r=".75"/><polygon fill="none" points="5,15 5,16 5,19 19,19 19,16 19,15 19,5 5,5"/><g><polygon points="11,14.17 8.83,12 11,9.83 9.59,8.41 6,12 9.59,15.59"/><polygon points="14.41,15.59 18,12 14.41,8.41 13,9.83 15.17,12 13,14.17"/><path d="M19,3h-4.18C14.4,1.84,13.3,1,12,1S9.6,1.84,9.18,3H5C4.86,3,4.73,3.01,4.6,3.04C4.21,3.12,3.86,3.32,3.59,3.59 c-0.18,0.18-0.33,0.4-0.43,0.64C3.06,4.46,3,4.72,3,5v10v1v3c0,0.27,0.06,0.54,0.16,0.78c0.1,0.24,0.25,0.45,0.43,0.64 c0.27,0.27,0.62,0.47,1.01,0.55C4.73,20.99,4.86,21,5,21h14c1.1,0,2-0.9,2-2v-3v-1V5C21,3.9,20.1,3,19,3z M12,2.75 c0.41,0,0.75,0.34,0.75,0.75S12.41,4.25,12,4.25s-0.75-0.34-0.75-0.75S11.59,2.75,12,2.75z M19,15v1v3H5v-3v-1V5h14V15z"/></g></g></svg>
            </button>&nbsp;
            <button title="Slet" (click)="deleteWidget(widget)">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg>
            </button>
        </div>
    </div>
    <p style="margin-top: 0;" *ngIf="widget.created_at != null">Oprettet {{ timestampToDateObject(widget.created_at).locale("da").format('LL') }}</p>
    <settings-widget-snippet *ngIf="showSnippetModal" [widget]="widget"></settings-widget-snippet>
    <div class="wrapper">
        <div class="group">
            <h4>Logo</h4>
            <div>
                <label class="block">
                    <input type="checkbox" [(ngModel)]="widget.settings.logo.enabled">{{" "}}
                    <span>Vis logo</span>
                </label>

                <div class="flex gap-sm align-items-center" style="margin-top: 24px" *ngIf="widget.settings.logo.enabled">
                    <img [src]="widget.settings.logo.path" height="32px">
                    <div>
                        <b>Sti til logo</b><br>
                        <input type="text" [(ngModel)]="widget.settings.logo.path">
                    </div>
                </div>
            </div>
        </div>

        <div class="group">
            <h4>Visning</h4>
            <div>
                <label class="block">
                    <input type="checkbox" [(ngModel)]="widget.settings.show.timestamps">{{" "}}
                    <span>Vis klokkeslæt</span>
                </label>
            </div>
        </div>

        <div class="group">
            <h4>Krav</h4>
            <div>
                <label class="block">
                    <input type="checkbox" [(ngModel)]="widget.settings.requirements.phone">{{" "}}
                    <span>Påkræv telefonnummer</span>
                </label>
            </div>
        </div>
    </div>
    <button class="submit" [disabled]="!editable()" (click)="submit(widget)">Gem</button>
</div>
