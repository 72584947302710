import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { ContainerComponent } from './container/container.component';
import { SettingsIndexComponent } from './index/settings-index.component';
import { EditWidgetComponent } from './edit-widget/edit-widget.component';
import {FormsModule} from "@angular/forms";
import { WidgetSnippetComponent } from './widget-snippet/widget-snippet.component';

@NgModule({
  declarations: [ContainerComponent, SettingsIndexComponent, EditWidgetComponent, WidgetSnippetComponent],
    imports: [
        CommonModule,
        SettingsRoutingModule,
        FormsModule,
    ]
})
export class SettingsModule { }
